import { GaxiosResponse, request } from 'gaxios';
import { RuleType } from 'features/Rules';

export const deleteRules = async (
  policy: string,
  selectedRules: string[] | { policy: string; docRefId: string }[],
  token: string,
  multiplePolicy?: string[],
): Promise<{ message: string; result: string; docRefIds: string[] }> => {
  const response: GaxiosResponse = await request({
    method: 'POST',
    url: multiplePolicy ? `/api/redirect/rules/delete/policy/multiple` : `/api/redirect/rules/delete/${policy}`,
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
    data: { docRefIds: selectedRules },
    params: { multiplePolicy: multiplePolicy && JSON.stringify(multiplePolicy) },
  });
  return response.data;
};

type RuleBody = {
  name: string;
  matchURL: string;
  targetURL: string;
  redirectType: number;
  dateStart: string;
  dateEnd: string | null;
  targetStatus: string | number;
};

export const addNewRule = async (policy: string, body: RuleBody, token: string): Promise<RuleType & { result: string }> => {
  const response: GaxiosResponse = await request({
    method: 'POST',
    url: `/api/redirect/rules/${policy}`,
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
    data: body,
  });
  return response.data;
};

export const updateExistingRule = async (policy: string, ruleId: string, body: RuleBody, token: string): Promise<RuleType & { result: string }> => {
  const response: GaxiosResponse = await request({
    method: 'PUT',
    url: `/api/redirect/rules/${policy}/${ruleId}`,
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
    data: body,
  });
  return response.data;
};

export const getUrlStatus = async (url: string, token: string): Promise<{ status: string }> => {
  const response: GaxiosResponse = await request({
    method: 'POST',
    url: '/api/redirect/status',
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
    data: { url },
  });
  return response.data;
};

export const getPathError = async (
  matchURL: string,
  policy: string,
  token: string,
  excludedDoc?: string,
): Promise<{ ruleExistenceResult?: string }> => {
  const response: GaxiosResponse = await request({
    method: 'POST',
    url: `/api/redirect/rules/${policy}/validate`,
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
    data: { matchURL, excludedDoc },
  });
  return response.data;
};
