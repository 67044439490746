import React, { useContext, useState } from 'react';
import Button from '@ingka/button';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle';
import { TextInputField } from 'components/common/TextInputField';
import * as StyledGlobalComponents from 'components/styles';
import { Context as RulesContext } from 'hooks/contexts/RulesContext';
import { Context as StatusMessageContext } from 'hooks/contexts/StatusMessageContext';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import { deleteRules } from '../../api/rules';

type RuleModalProps = {
  isVisible: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  selectedRules: string[] | { policy: string; docRefId: string }[];
  ruleName?: string;
  ruleTargetURL?: string;
  policy: string;
  multiplePolicy?: string[];
};

export const DeleteRuleModal: React.FC<RuleModalProps> = ({
  isVisible,
  onCancel,
  onSubmit,
  selectedRules,
  ruleName,
  ruleTargetURL,
  policy,
  multiplePolicy,
}) => {
  const { setRefetchAfter } = useContext(RulesContext);
  const statusMessage = useContext(StatusMessageContext);
  const {
    state: { token },
  } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationString, setConfirmationString] = useState<string>('');
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const isBulkDelete = selectedRules.length > 1;
  const ruleDisplayName = ruleName ? ruleName : ruleTargetURL;
  const onDeleteRule = async () => {
    setIsLoading(true);
    const response = await deleteRules(policy, selectedRules, token, multiplePolicy);
    if (response?.result === 'ok') {
      onSubmit();
      statusMessage.setStatusMessage({
        isVisible: true,
        variant: 'positive',
        title: 'Rule deleted',
        bodyText: 'You have successfully deleted the rule!',
        actions: [],
      });
      setRefetchAfter(`Deleted_${new Date()}`);
    } else {
      onCancel();
      statusMessage.setStatusMessage({
        isVisible: true,
        variant: 'cautionary',
        title: 'Oops!',
        bodyText: 'Something went wrong when deleting the rule! Please try again later',
        actions: [],
      });
    }
    setIsLoading(false);
  };

  const confirmDelete = () => {
    if (confirmationString.toLowerCase().trim() === 'delete') {
      onDeleteRule();
    } else {
      setShowErrorMessage(true);
    }
    setConfirmationString('');
  };

  const closeModal = () => {
    onCancel();
    setTimeout(() => {
      setConfirmationString('');
      setShowErrorMessage(false);
    }, 200);
  };

  return (
    <Modal escapable={false} visible={isVisible} handleCloseBtn={() => closeModal()}>
      <Prompt
        titleId={isBulkDelete ? 'Delete rules?' : 'Delete rule?'}
        title={isBulkDelete ? 'Delete rules?' : 'Delete rule?'}
        header={<ModalHeader />}
        footer={
          <ModalFooter>
            <Button text={'Cancel'} type={'secondary'} onClick={() => closeModal()} size={'small'} disabled={isLoading} />
            <Button
              text={isBulkDelete ? 'Yes, delete rules' : 'Yes, delete rule'}
              type={'danger'}
              disabled={confirmationString.length <= 0}
              loading={isLoading}
              onClick={confirmDelete}
              size={'small'}
              ssrIcon={warningTriangle}
            />
          </ModalFooter>
        }
      >
        {showErrorMessage && (
          <StyledGlobalComponents.DeleteErrorInlineMessage
            variant={'cautionary'}
            title={'Oops! Something went wrong :('}
            body={'Please make sure you spelled "Delete" correctly'}
          />
        )}

        <StyledGlobalComponents.ConfirmDeleteText tagName="p" bodySize="l">
          {isBulkDelete ? (
            <>
              Do you really want to delete <strong>{selectedRules.length}</strong> rules?
            </>
          ) : (
            <>
              Are you sure you want to delete the rule: <i> {`${ruleDisplayName}?`}</i>
            </>
          )}
        </StyledGlobalComponents.ConfirmDeleteText>

        <TextInputField
          id={'deleteRuleInput'}
          label={'Please confirm by writing "Delete" below'}
          onChange={(e) => setConfirmationString(e.target.value)}
          value={confirmationString}
        />
      </Prompt>
    </Modal>
  );
};
