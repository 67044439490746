import { request } from 'gaxios';
import { RuleType } from 'features/Rules';
import { useRequestWithTokenAndTransformer } from 'hooks/useWithToken';
import { RuleTypeDiff, UploadedRules } from './UploadRulesDiff';
import { FilterInputRules } from '../../hooks/contexts/FilterContext';
import { UsageOption } from 'features/Rules/UsageRangeModal';

type RuleServiceParamsType = {
  refetchAfter: string;
  policy: string;
  pageLimit?: number;
  page?: number;
  searchValue?: string;
  sort?: string;
  multiplePolicies?: string[];
  month?: string;
  year?: number;
} & FilterInputRules;

type RuleParamsType = {
  pageLimit?: number;
  page?: number;
  search?: string;
  sort?: string;
  multiplePolicies?: string;
  month?: string;
  year?: number;
} & FilterInputRules;

type RulesResponse = {
  rules: RuleType[];
  totalRules: number;
  pageLimit: number;
  page: number;
  searchValue?: string;
  usageOptions?: UsageOption[];
  multiplePolicies?: string[];
};

const createParamsObject = (params: RuleServiceParamsType): RuleParamsType => {
  const { searchValue, pageLimit, page, sort, multiplePolicies, month, year, targetStatus, invocations } = params;
  let paramsObject: RuleParamsType = {
    search: searchValue,
    pageLimit,
    page,
    sort,
    multiplePolicies: JSON.stringify(multiplePolicies),
    month,
    year,
  };
  if (targetStatus) paramsObject = { ...paramsObject, targetStatus };
  if (invocations) paramsObject = { ...paramsObject, invocations };
  return paramsObject;
};

export const useFetchRules = (params: RuleServiceParamsType) => {
  const { refetchAfter, searchValue, policy, pageLimit, page, sort, targetStatus, invocations, month, year } = params;
  const paramsObject = createParamsObject(params);
  const { data, loading } = useRequestWithTokenAndTransformer<RulesResponse, RulesResponse>(
    {
      url: `/api/redirect/rules/${policy}`,
      params: paramsObject,
    },
    [pageLimit, page, searchValue, refetchAfter, sort, targetStatus, invocations, month, year],
    ({ rules, totalRules, pageLimit, page, usageOptions }) => ({ rules, totalRules, pageLimit, page, usageOptions }),
  );
  return { data, loading };
};

export const fetchUploadedRulesByIds = async (
  policy: string,
  id: string,
  docIds: string[],
  token: string,
): Promise<{
  uploadedRules: RuleTypeDiff[];
}> => {
  const response =
    docIds.length > 0 &&
    (await request({
      url: `/api/redirect/rules/${policy}/upload-diff/${id}`,
      method: 'POST',
      data: { docIds: docIds.slice(0, 30) },
      headers: { Authorization: `Bearer ${token}` },
    }));
  if (!response) {
    throw new Error('No ids provided!');
  }
  return { uploadedRules: (response.data as UploadedRules).uploadedRules as RuleTypeDiff[] };
};
