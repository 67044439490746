import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@ingka/button';
import { Pagination } from 'components/common/Pagination';
import { Column } from 'components/common/Table';
import { usePaginator } from 'hooks/usePaginator';
import { PaginationT } from 'opt-util/paginate';
import { Message, MetadataProps } from 'types';
import ComparisonTable, { IComparisonRow } from './ComparisonTable';
import DeletedFiles from './DeletedEntries';
import { UploadPublishButton } from './PublishButton';
import UploadErrors from './UploadErrors';

const getCountry = (options?: MetadataProps) => (options ? options.country : '');
const getLang = (options?: MetadataProps) => (options ? options.language : '');

interface ValidationResultProps {
  result: PaginationT<IComparisonRow>;
  options: MetadataProps;
  columns: Column[];
  uploadId: string;
  errorMessages: Message[];
}

export const ValidationResult: React.FC<ValidationResultProps> = (props) => {
  const { totalItems, pageSize } = props.result;
  const paginator = usePaginator({ totalItems, itemsPerPage: pageSize });
  const history = useHistory();
  const backToListing = useCallback(() => {
    history.push(`/metadata/${props.options.country}/${props.options.language}/${props.options.type}`);
  }, [props.options]);
  return (
    <div>
      <UploadErrors messages={props.errorMessages} />
      <DeletedFiles deletedFiles={props.errorMessages.filter((m) => m.messages)} />
      {props.result.totalPages > 0 ? (
        <>
          <hr />
          <Pagination paginator={paginator} />
          <h4>Rows that will be uploaded</h4>
          <p>You need to press Publish button to publish the uploaded entries to production</p>
          <ComparisonTable
            striped
            data={props.result.data[paginator.page - 1]}
            columns={props.columns}
            options={props.options}
            uploadId={props.uploadId}
          />
          <UploadPublishButton uploadId={props.uploadId} country={getCountry(props.options)} language={getLang(props.options)} />
          <Pagination paginator={paginator} />
        </>
      ) : (
        <>
          <hr />
          <h4>No rows will be uploaded</h4>
          <UploadPublishButton uploadId={props.uploadId} country={getCountry(props.options)} language={getLang(props.options)} />
          <Button key={'cancel-button'} text={'Cancel'} type={'tertiary'} onClick={backToListing} />
        </>
      )}
    </div>
  );
};
