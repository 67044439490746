import React, { SyntheticEvent, useState } from 'react';
import Button from '@ingka/button';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle';
import { TextInputField } from 'components/common/TextInputField';
import * as StyledGlobalComponents from 'components/styles';
import { useLoader } from 'hooks/useLoader';
import { FilterInput, useDeleteMetaDataEntryMutation } from '__generated__/types';
import { useLocation } from 'react-router-dom';

export interface DeleteEntryModalProps {
  entryId: string;
  pageTitle: string;
  isVisible: boolean;
  filter: FilterInput;
  onCancel: () => void;
  onDelete: () => void;
}

export const DeleteEntryModal: React.FC<DeleteEntryModalProps> = ({ entryId, pageTitle, isVisible, onCancel, onDelete, filter }) => {
  const [deleteItem] = useDeleteMetaDataEntryMutation();
  const [loading, setLoading] = useLoader();
  const [confirmationString, setConfirmationString] = useState<string>('');
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const location = useLocation();
  const [, , country, language, type] = location.pathname.split('/');

  const deleteItemCall = async (e: SyntheticEvent) => {
    setLoading(true);
    try {
      try {
        const res = await deleteItem({
          variables: { country, language, type, entryId, filter },
        });
        res.data && onDelete();
        closeModal();
      } catch (e) {
        console.log(e);
      }
    } finally {
      setLoading(false);
    }
  };

  const confirmDelete = async (e: SyntheticEvent) => {
    if (confirmationString.toLowerCase().trim() === 'delete') {
      await deleteItemCall(e);
    } else {
      setShowErrorMessage(true);
    }
  };

  const closeModal = () => {
    onCancel();
    setTimeout(() => {
      setConfirmationString('');
      setShowErrorMessage(false);
    }, 200);
  };

  return (
    <Modal escapable={false} visible={isVisible} handleCloseBtn={() => closeModal()}>
      <Prompt
        titleId={'Delete'}
        title={'Delete'}
        header={<ModalHeader />}
        footer={
          <ModalFooter>
            <Button text={'No'} disabled={loading} type={'secondary'} onClick={() => closeModal()} loading={loading} size={'small'} />
            <Button
              text={'Yes, delete entry'}
              type={'danger'}
              disabled={confirmationString.length <= 0}
              onClick={confirmDelete}
              loading={loading}
              ssrIcon={warningTriangle}
              size={'small'}
            />
          </ModalFooter>
        }
      >
        {showErrorMessage && (
          <StyledGlobalComponents.DeleteErrorInlineMessage
            variant={'cautionary'}
            title={'Oops! Something went wrong :('}
            body={'Please make sure you spelled "Delete" correctly'}
          />
        )}

        <StyledGlobalComponents.ConfirmDeleteText tagName="p" bodySize="l">
          Do you really want to delete the entry: <i>{pageTitle ? `${pageTitle}?` : 'There is no Page Title.'}</i>
        </StyledGlobalComponents.ConfirmDeleteText>

        <TextInputField
          id={'deleteMetaDataInput'}
          label={'Please confirm by writing "Delete" below'}
          onChange={(e) => setConfirmationString(e.target.value)}
          value={confirmationString}
        />
      </Prompt>
    </Modal>
  );
};
