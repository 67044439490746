import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { environment } from 'environment';
import { AnnouncementBanner } from 'components/admin/announcement/AnnouncementBanner';
import ErrorBoundary from 'components/ErrorBoundary';
import { FeedbackSurvey } from 'components/seo/survey';
import { Header } from 'features/Header';
import { SideNav } from 'features/Header/SideNav';
import { Provider as MetaDataProvider } from 'hooks/contexts/MetaDataContext';
import { Provider as RulesContext } from 'hooks/contexts/RulesContext';
import { Provider as SideNavProvider } from 'hooks/contexts/SideNavContext';
import { Provider as StatusMessageContext } from 'hooks/contexts/StatusMessageContext';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import { Provider as FeedbackProvider } from 'hooks/contexts/FeedbackContext';
import { FilterProvider } from 'hooks/contexts/FilterContext';
import { Context as ErrorMessageContext, Provider as ErrorMessageProvider } from 'hooks/contexts/ErrorMessageContext';
import { useFeatureContext } from 'hooks/contexts/FeatureContext';
import { useCountry } from 'hooks/useCountry';
import { Elm } from 'types/React';
import { UploadRulesDiff } from './RulesPage/UploadRulesDiff';
import { DataViewPage } from './seo/DataViewPage';
import UploadCountryPage from './seo/UploadCountryPage';
import { UploadPage } from './seo/UploadPage';
import { UploadResultPage } from './seo/UploadResultPage';
import { SitemapsPage } from './sitemaps/SitemapsPage';
import { SitemapStatsDetailView } from './sitemaps/SitemapStatsDetailView';
import { AdminPage } from './AdminPage';
import { BookmarkPage } from './BookmarkPage';
import { CountrySelectPage } from './CountrySelectPage';
import { Dashboard } from './Dashboard';
import { GraphsPage } from './GraphsPage';
import { MetaData } from './Metadata';
import { RedirectsPage } from './RedirectsPage';
import { RulesPage } from './RulesPage';
import * as Styled from './styles';
import { ApolloClient, ApolloProvider, concat } from '@apollo/client';
import { cache, createAuthMiddleware, httpLink } from '../api/graphql';
import { SystemStatusMessage } from 'components/SystemStatusMessage';
import { NotFoundPage } from './NotFoundPage';
import { sendPageLoadEvent } from 'opt-util/episod';

export const PageWrapper: React.FC<PropsWithChildren> = (props: PropsWithChildren) => {
  const { state } = useContext(ErrorMessageContext);
  const { pathname } = useLocation();

  useEffect(() => {
    sendPageLoadEvent(pathname);
  }, []);

  return (
    <div className={'page'}>
      <StatusMessageContext>
        <RulesContext>
          <MetaDataProvider>
            <FilterProvider>
              <AnnouncementBanner />
              <ErrorBoundary>
                <Header />
              </ErrorBoundary>
              <ErrorBoundary hasContextError={state.hasError}>
                <Styled.PageContainer>
                  <SideNav />
                  <Styled.PageContent>
                    <SystemStatusMessage />
                    {props.children}
                  </Styled.PageContent>
                </Styled.PageContainer>
              </ErrorBoundary>
            </FilterProvider>
          </MetaDataProvider>
        </RulesContext>
        <FeedbackSurvey />
      </StatusMessageContext>
    </div>
  );
};
const wrapped =
  <T extends {}>(Component: React.ComponentType<T>) =>
  (props: T): Elm<T> => (
    <PageWrapper>
      <Component {...props} />
    </PageWrapper>
  );

export const AuthenticatedRoutes: React.FC = () => (
  <>
    <Switch>
      <Route path={'/'} component={wrapped(Dashboard)} exact />
      <Route path={'/dashboard'} component={wrapped(Dashboard)} exact />
      <Route path={'/admin/:tool'} component={wrapped(AdminPage)} />
      <Route path={'/sitemaps'} exact component={wrapped(SitemapsPage)} />
      <Route path={'/sitemaps/stats/:language-:country'} component={wrapped(SitemapStatsDetailView)} />
      <Route path={'/graphs'} component={wrapped(GraphsPage)} />
      <Route path={'/redirects/:country'} component={wrapped(RedirectsPage)} exact />
      <Route path={'/redirects/:country/:policy'} component={wrapped(RulesPage)} exact />
      <Route path={'/redirects/:country/:policy/upload-diff/:id'} component={wrapped(UploadRulesDiff)} exact />
      <Route path={'/metadata'} component={wrapped(MetaData)} exact />
      <Route path={'/metadata/upload/:country/:language/:type'} component={wrapped(UploadCountryPage)} />
      <Route path={'/metadata/upload'} component={wrapped(UploadPage)} />
      <Route path={'/metadata/uploadResult/:country/:language/:type'} component={wrapped(UploadResultPage)} />
      <Route path={'/metadata/:country/:language/:type'} component={wrapped(DataViewPage)} />
      <Route path={'/bookmarklet'} component={wrapped(BookmarkPage)} />
      <Redirect from="/login" to="/" />
      <Route path={'*'} component={wrapped(NotFoundPage)} />
    </Switch>
  </>
);

export const Routes: React.FC = () => {
  const originalPath = sessionStorage.getItem('originalPath');
  const [country] = useCountry();
  const history = useHistory();
  const {
    state: { isLoggedIn },
    refreshToken,
  } = useContext(LoginContext);
  const { updateFeatureList } = useFeatureContext();

  const updatePath = () => {
    if (originalPath) {
      sessionStorage.removeItem('originalPath');
      if (window.location.pathname !== originalPath) {
        history?.push(originalPath);
      }
    }
  };

  const getFeatures = async () => {
    const token = await refreshToken();
    updateFeatureList(token);
  };

  useEffect(() => {
    updatePath();
    getFeatures();
  }, []);

  const apolloClient = new ApolloClient({
    link: concat(createAuthMiddleware(refreshToken), httpLink),
    cache,
  });

  if (isLoggedIn) {
    if (!country) {
      return (
        <ApolloProvider client={apolloClient}>
          <Router>
            <CountrySelectPage />
          </Router>
        </ApolloProvider>
      );
    }
    return (
      <ApolloProvider client={apolloClient}>
        <Router>
          <ErrorMessageProvider>
            <FeedbackProvider>
              <SideNavProvider>
                <AuthenticatedRoutes />
              </SideNavProvider>
            </FeedbackProvider>
          </ErrorMessageProvider>
        </Router>
      </ApolloProvider>
    );
  } else {
    if (!originalPath) {
      sessionStorage.setItem('originalPath', window.location.pathname);
    }
    window.location.replace(environment.serviceUrl + environment.backendLoginPath);
    return null;
  }
};
