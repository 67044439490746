import React from 'react';
import Button from '@ingka/button';
import chevronRight from '@ingka/ssr-icon/paths/chevron-right-small';
import { TableBody, TableHeader } from '@ingka/table';
import Text from '@ingka/text';
import LocaleHeader from 'components/common/LocaleHeader';
import { LatestByCountryQuery } from '__generated__/types';
import * as Styled from './styles';
import { FileType, useMetaDataExport } from '../../hooks/useMetaDataExport';
import arrowDown from '@ingka/ssr-icon/paths/arrow-down-to-line';
import arrowUp from '@ingka/ssr-icon/paths/arrow-up-to-line';
import { useHistory } from 'react-router-dom';
import { FloatingMenuWithPillButton } from '../../components/FloatingMenuWithPillButton';

export const MetaDataListTable: React.FC<{
  country: string;
  language: string;
  versions: LatestByCountryQuery['latestVersionsForCountry'];
}> = ({ country, language, versions }) => {
  const columnHeaders = ['Type', 'Modified', 'Edited by', '', ''];
  const { exportMetaData } = useMetaDataExport();
  const history = useHistory();
  const onNavigate = (type: string) => history.push(`/metadata/${country}/${language}/${type}`);
  return (
    <Styled.PageWrapper>
      <LocaleHeader country={country} language={language} />

      <Styled.Table fullWidth data-testid={`${country}-${language}`}>
        <TableHeader>
          <tr>
            {columnHeaders.map((header, key) => (
              <th key={key}>{header}</th>
            ))}
          </tr>
        </TableHeader>
        <TableBody striped>
          {versions.map((version, key) => {
            const downloadFile = async (format: FileType) =>
              await exportMetaData(
                { page: 0 },
                {
                  country: country,
                  language: language,
                  type: version.type,
                },
                format,
              );
            const floatingMenuItems = [
              {
                onClick: () => downloadFile('xlsx'),
                text: ' Download',
                icon: arrowDown,
                dataTestId: 'download-xlsx-btn',
              },
              {
                onClick: () => history.push(`/metadata/upload/${country}/${language}/${version.type}`),
                text: 'Upload',
                icon: arrowUp,
                dataTestId: 'upload-xlsx-btn',
              },
            ];

            return (
              <tr key={key} data-testid={`row-${version.type}`}>
                <td>
                  <Text>{version.type.toUpperCase()}</Text>
                </td>
                <td>
                  <Text>{new Date(version.ts).toLocaleString()}</Text>
                </td>
                <td>
                  <Text>{version.author}</Text>
                </td>
                <td>
                  <Button
                    text="View"
                    iconPosition="trailing"
                    ssrIcon={chevronRight}
                    size={'small'}
                    type="secondary"
                    onClick={() => onNavigate(version.type)}
                    data-cy={'view-button'}
                  />
                </td>
                <td>
                  <FloatingMenuWithPillButton menuItems={floatingMenuItems} />
                </td>
              </tr>
            );
          })}
        </TableBody>
      </Styled.Table>
    </Styled.PageWrapper>
  );
};
