import { FC, useState } from 'react';
import Modal, { ModalFooter, ModalHeader } from '@ingka/modal';
import dataChartIcon from '@ingka/ssr-icon/paths/data-chart';
import Button from '@ingka/button';
import { useFilter } from 'hooks/contexts/FilterContext';
import { getPreviousMonthAndYear } from 'utils/dates';
import * as Styled from './styled';
import { Autocomplete } from 'components/Autocomplete';

export type UsageOption = {
  month: string;
  year: number;
};

const formatOption = (usageOption: UsageOption): string => `${usageOption.month} ${usageOption.year}`;

const UsageRangeModal: FC<{ usageOptions: UsageOption[] }> = ({ usageOptions }) => {
  const { setFilter } = useFilter();
  const [open, setOpen] = useState<boolean>(false);
  const [month, setMonth] = useState<string>(formatOption(getPreviousMonthAndYear(new Date())));
  const [availableUsageOptions] = useState<string[]>(usageOptions.map(formatOption));

  const handleConfirm = () => {
    setOpen(!open);
    setFilter((prev) => ({ ...prev, year: Number(month.split(' ')[1]), month: month.split(' ')[0] }));
  };

  return (
    <>
      <Button type="tertiary" data-testid="usage-modal-button" text={'Usage'} size="small" ssrIcon={dataChartIcon} onClick={() => setOpen(true)} />
      <Modal
        visible={open}
        handleCloseBtn={() => {
          setOpen(!open);
        }}
      >
        <Styled.UsageRangePrompt
          titleId={'Redirect usage filter'}
          title={'Redirect usage filter'}
          header={<ModalHeader />}
          footer={
            <ModalFooter>
              <Button
                text={'Cancel'}
                size="small"
                type={'secondary'}
                onClick={() => {
                  setOpen(!open);
                }}
              />
              <Button text={'Confirm'} data-testid="usage-modal-confirm" type={'primary'} size="small" onClick={handleConfirm} />
            </ModalFooter>
          }
        >
          <p>Month picker gives you the total amount of redirects for a specific month.</p>
          <Styled.MonthDropdown>
            <Autocomplete
              options={availableUsageOptions.map((option) => ({ id: option, name: option }))}
              onOptionSelect={({ name }) => setMonth(name)}
              value={month}
            />
          </Styled.MonthDropdown>
        </Styled.UsageRangePrompt>
      </Modal>
    </>
  );
};

export default UsageRangeModal;
