import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { menu } from '@ingka/ssr-icon/icons';
import chevronLeft from '@ingka/ssr-icon/paths/chevron-left';
import { UploadRulesModal } from 'pages/RulesPage/UploadRulesModal';
import { CountrySelectButton } from 'features/CountrySelectButton';
import { Context as MetaDataContext } from 'hooks/contexts/MetaDataContext';
import { Context as RulesContext } from 'hooks/contexts/RulesContext';
import { Context as SideNavContext } from 'hooks/contexts/SideNavContext';
import { AddRedirectRuleModalButton } from './AddRedirectRuleModalButton';
import { HelpModalButton } from './HelpModalButton';
import { Profile } from './Profile';
import { UploadRulesButtons } from './UploadRulesButtons';
import * as Styled from './styles';
import { useCountry } from '../../hooks/useCountry';

export const Header: React.FC = () => {
  const sideNav = useContext(SideNavContext);
  const [country] = useCountry();
  const {
    state: { uploadRulesModal },
  } = useContext(RulesContext);
  const {
    state: { metaDataContext },
  } = useContext(MetaDataContext);
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [, , , policy] = pathname.split('/');
  const isRedirectPage = pathname.match(/redirects\/(\w{2}|global)\/\w{2,}/);
  const isRedirectUploadDiffPage = pathname.includes('upload-diff');
  const isMetaDataView = pathname.includes(`/metadata/${metaDataContext.country}/${metaDataContext.language}`);
  const history = useHistory();
  const multiplePolicies = searchParams.get('policy')?.split(',');
  const sortParams = searchParams.get('sort')?.split(',');
  const headingLanguage = multiplePolicies ? 'All languages' : policy?.toUpperCase();
  const headingPage = sortParams ? 'Default-URL - ' : <p>Redirect</p>;
  const onNavigateBack = (path: string) => {
    history.replace(path);
  };
  return (
    <>
      <Styled.Header>
        {isRedirectPage ? (
          <>
            {isRedirectUploadDiffPage ? (
              <>
                <Styled.MenuContainerLeftWithSearch>
                  <Styled.BackIcon
                    data-testid={'back-button'}
                    ssrIcon={chevronLeft}
                    size={'small'}
                    iconOnly
                    type={'tertiary'}
                    onClick={() => history.goBack()}
                  />
                  <Styled.CountryText>
                    <p> Import rules </p>
                    {policy.toUpperCase()}
                  </Styled.CountryText>
                </Styled.MenuContainerLeftWithSearch>
                <Styled.MenuContainerRight>
                  <UploadRulesButtons />
                </Styled.MenuContainerRight>
              </>
            ) : (
              <>
                <Styled.MenuContainerLeftWithSearch>
                  {!sortParams && (
                    <Styled.BackIcon
                      data-testid={'back-button'}
                      ssrIcon={chevronLeft}
                      size={'small'}
                      iconOnly
                      type={'tertiary'}
                      onClick={() => onNavigateBack(`/redirects/${country?.countryCode}`)}
                      inverseTheme
                    />
                  )}
                  <Styled.CountryText $marginLeft={sortParams && '0px'}>
                    {headingPage}
                    {headingLanguage}
                  </Styled.CountryText>
                </Styled.MenuContainerLeftWithSearch>
                <Styled.MenuContainerRight>
                  <AddRedirectRuleModalButton />
                </Styled.MenuContainerRight>
              </>
            )}
          </>
        ) : (
          <>
            {isMetaDataView ? (
              <>
                <Styled.MenuContainerLeft>
                  <Styled.BackIcon
                    data-testid={'back-button'}
                    ssrIcon={chevronLeft}
                    size={'small'}
                    iconOnly
                    type={'tertiary'}
                    onClick={() => onNavigateBack('/metadata')}
                  />
                  <div>
                    <Styled.EditHeaderText tagName="p"> {metaDataContext && `${metaDataContext.type.toUpperCase()}`}</Styled.EditHeaderText>
                    <Styled.EditHeaderText tagName="p">
                      {metaDataContext && `${metaDataContext.country}-${metaDataContext.language}`}
                    </Styled.EditHeaderText>
                  </div>
                </Styled.MenuContainerLeft>
                <Styled.MenuContainerRight>
                  <CountrySelectButton inverseTheme={true} />
                  <HelpModalButton />
                  <Profile />
                </Styled.MenuContainerRight>
              </>
            ) : (
              <>
                <Styled.MenuContainerLeft>
                  <Styled.BurgerMenu
                    data-testid={'burger-menu-button'}
                    ssrIcon={menu}
                    size={'small'}
                    iconOnly
                    type={'tertiary'}
                    onClick={() => sideNav.setIsOpen(!sideNav.state.isOpen)}
                    inverseTheme
                  />
                  <a href="/">
                    <span>Optimera</span>
                  </a>
                </Styled.MenuContainerLeft>
                <Styled.MenuContainerRight>
                  <CountrySelectButton inverseTheme={true} />
                  <HelpModalButton />
                  <Profile />
                </Styled.MenuContainerRight>
              </>
            )}
          </>
        )}
      </Styled.Header>
      {uploadRulesModal.isOpen && <UploadRulesModal isVisible={uploadRulesModal.isOpen} />}
    </>
  );
};
