import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@ingka/button';
import { TextInputField } from 'components/common/TextInputField';
import { useCreateLocaleMutation, useExistingSiteFoldersQuery } from '__generated__/types';
import { ErrorMessage } from 'components/common/styles';
import { useCountry } from '../../hooks/useCountry';
import { getCountryName } from '../../opt-util/helpers';

export const UploadNewLocale: React.FC = () => {
  const [createError, setCreateError] = useState('');
  const [formData, setFormData] = useState('');
  const { data } = useExistingSiteFoldersQuery();
  const [create] = useCreateLocaleMutation();
  const [, setCountry] = useCountry();
  const history = useHistory();
  const handleSubmit = (siteFolder: string) => {
    const [country, language] = siteFolder.split('/');
    setCreateError('');
    return create({ variables: { country, language } })
      .then(() => {
        setCountry({ countryCode: country, countryName: getCountryName(country) });
        history.push('/metadata');
      })
      .catch((e) => setCreateError(e.message));
  };
  const existing = data ? data.existingSiteFolders : [];
  const validationFunction = (value: string) => {
    if (!value.match(/\w+\/\w+/)) {
      return 'Site folder should be in format "xx/yy"';
    }
    if (existing.indexOf(value) !== -1) {
      return `Site folder ${value} already exists in Optimera`;
    }
  };
  return (
    <>
      {createError && <ErrorMessage>{createError}</ErrorMessage>}
      <TextInputField
        shouldValidate={true}
        id={'siteFolder'}
        label={'Site folder'}
        errorMessage={validationFunction(formData)}
        onChange={(e) => setFormData(e.target.value)}
        value={formData}
      />
      <Button text={'OK'} type={'primary'} onClick={() => handleSubmit(formData)} />
    </>
  );
};
