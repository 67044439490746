import React, { useState } from 'react';
import Button from '@ingka/button';
import Hyperlink from '@ingka/hyperlink';
import Modal, { ModalBody, ModalFooter, ModalHeader, Sheets } from '@ingka/modal';
import questionMarkCircle from '@ingka/ssr-icon/paths/question-mark-circle';
import * as Styled from './styles';

type Link = { url: string; title: string };
const links: Link[] = [
  { url: 'https://confluence.build.ingka.ikea.com/display/GROWMKT/Optimera+-+Guide', title: 'Optimera - Guide' },
  { url: 'https://ingka.slack.com/archives/C03098HRAJW', title: 'Support' },
];
export const HelpModalButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        iconOnly={true}
        ssrIcon={questionMarkCircle}
        size={'small'}
        type={'tertiary'}
        inverseTheme={true}
        data-testid={'help-modal-button'}
        onClick={() => setIsOpen(true)}
      />
      <Modal handleCloseBtn={() => setIsOpen(false)} visible={isOpen}>
        <Sheets
          alignment={'right'}
          size={'small'}
          header={<ModalHeader title="Need Help?" />}
          footer={
            <ModalFooter>
              <Button text="Close" type="primary" onClick={() => setIsOpen(false)} />
            </ModalFooter>
          }
        >
          <ModalBody>
            <Styled.UrlList>
              {links.map(({ url, title }) => (
                <Hyperlink key={url} url={url} subtle newWindow>
                  {title}
                </Hyperlink>
              ))}
            </Styled.UrlList>
          </ModalBody>
        </Sheets>
      </Modal>
    </>
  );
};
