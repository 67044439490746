import styled from '@emotion/styled';
import Button from '@ingka/button';
import Loading from '@ingka/loading';
import { Prompt } from '@ingka/modal';
import Pill from '@ingka/pill';
import RadioButtonGroup from '@ingka/radio-button-group';
import IngkaTable from '@ingka/table';
import { space125, space50 } from '@ingka/variables';
import {
  colourNeutral2,
  colourSemanticCaution,
  colourSemanticNegative,
  colourSemanticPositive,
  colourTextAndIcon4,
} from '@ingka/variables/colours-css';
import { Autocomplete } from 'components/Autocomplete';
import { FlexContainer } from 'components/FlexContainer';
import { DropdownInput } from 'components/common/DropdownInput';

export const Table = styled(IngkaTable)<{ targetElementIndex?: number; offsettop?: number }>`
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  height: ${(props) => `calc(100vh - ${props.offsettop}px - ${space125} - ${space50})`};

  th {
    padding-left: 12px;
    padding-right: 12px;

    :nth-of-type(${(props) => props.targetElementIndex || 2}):nth-of-type(${(props) => (props.targetElementIndex || 2) + 1}) {
      width: 100%;
    }
  }

  .text {
    color: #111111 !important;
  }

  .table,
  tbody,
  td {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    vertical-align: middle;
    word-break: break-word;

    :nth-of-type(${(props) => props.targetElementIndex || 2}):nth-of-type(${(props) => (props.targetElementIndex || 2) + 1}) {
      width: 25%;
    }
  }

  tr {
    :nth-of-type(even) {
      background-color: ${colourNeutral2};
    }

    :nth-of-type(odd) {
      background-color: transparent;
    }
  }
`;

export const SortIcon = styled.span<{ isSortedColumn: boolean }>`
  visibility: ${({ isSortedColumn }) => (isSortedColumn ? 'visible' : 'hidden')};
`;

export const TableHeading = styled('th')<{ width?: number }>`
  width: ${({ width }) => `${width}%`};

  :nth-of-type(n + 3):not(:nth-last-of-type(-n + 2)) {
    cursor: pointer;
  }

  div {
    .text {
      white-space: nowrap;
    }
  }
`;

export const TableColumnHeading = styled(FlexContainer)`
  height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    width: 22px;
    height: 22px;
  }

  &:hover {
    #sort-icon {
      visibility: visible;
      color: ${colourTextAndIcon4};
    }
  }
`;

export const PillButton = styled(Pill)`
  background-color: transparent;
`;

export const TargetStatusSuccess = styled.div`
  display: flex;
  color: ${colourSemanticPositive};
  font-size: 12px;
  align-items: center;
  gap: 4px;
`;

export const TargetStatusError = styled.div`
  display: flex;
  color: ${colourSemanticNegative};
  font-size: 12px;
  align-items: center;
  gap: 4px;
`;

export const TargetStatusWarning = styled.div`
  display: flex;
  color: ${colourSemanticCaution};
  font-size: 12px;
  align-items: center;
  gap: 4px;
`;

export const TargetStatusNone = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  gap: 4px;
`;

export const Container = styled.div`
  padding: 20px;
`;

export const PromptWithWidth = styled(Prompt)`
  .prompt__content-wrapper {
    min-width: 50rem;
    max-width: fit-content;
  }

  .input-field--error.input-field {
    margin-bottom: 8px;
  }
`;

export const FlexWithWidth = styled(FlexContainer)<{ mt?: number }>`
  margin-top: ${({ mt }) => `${mt}px`};

  .form-field {
    width: 100%;
  }
`;
export const RadioButtonGroupPosition = styled(RadioButtonGroup)`
  legend {
    padding-top: 12px;
  }

  .radio:not(:last-child),
  .radio {
    margin-bottom: 8px;
  }
`;

export const RuleModalHeader = styled.div`
  display: flex;
  gap: 12px;
`;

export const TargetStatusContainer = styled.div`
  width: max-content;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`;

export const TargetStatusMessage = styled.div`
  height: 20px;
  margin-bottom: 20px;
`;

export const TargetStatusMessageLoader = styled(Loading)`
  padding-top: 10px;
`;
export const UploadRulesErrorText = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: red;
`;

export const EditDeleteButtonContainer = styled.div`
  display: flex;
  gap: $(space50);
`;

export const EditDeleteButton = styled(Button)`
  min-height: 2.225rem;
  min-width: 7rem;
`;

export const InvocationColumn = styled.td`
  text-align: center;
`;

export const MonthDropdown = styled.div`
  padding-top: 25px;
`;

export const UsageRangePrompt = styled(Prompt)`
  overflow: visible;

  .prompt__content-wrapper {
    overflow: visible;
  }
  .prompt__content {
    overflow: visible;
  }
`;
