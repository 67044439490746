import { GaxiosResponse, request } from 'gaxios';

const optimeraService = '/api/optimera';

type SurveyInput = {
  question: string;
  rating?: number;
  answer?: string;
  contactMe?: boolean;
  feedbackEvent?: string | null;
};

export const saveSurvey = async (token: string, survey: SurveyInput): Promise<GaxiosResponse> =>
  await request({
    method: 'POST',
    url: `${optimeraService}/survey`,
    headers: { Authorization: `Bearer ${token}` },
    data: survey,
  });
