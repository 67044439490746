import React, { BaseSyntheticEvent, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Loading, { LoadingBall } from '@ingka/loading';
import Modal, { ModalHeader, Prompt } from '@ingka/modal';
import warningTriangleIcon from '@ingka/ssr-icon/paths/warning-triangle';
import { ContainerWrapper } from 'components/common/Container';
import { Context as MetaDataContext } from 'hooks/contexts/MetaDataContext';
import { FilterContextInputs, FilterContextInputsKeyWithoutPage, useFilter } from 'hooks/contexts/FilterContext';
import { useLoader } from 'hooks/useLoader';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import { Column as TableColumn, Column as MetaDataTableColumns } from 'types';
import { FilterInput, SortInput, useCurrentVersionQuery } from '__generated__/types';
import { MetaDataEntryTableData } from './MetadataEntryTableData';
import * as Styled from './styles';
import { useMetaDataExport } from '../../../hooks/useMetaDataExport';
import { Control } from '../../Control';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { CheckboxFields } from '../../CheckboxGroup';
import { generateColumnToCheckBoxesOptions } from '../../Control/helper';
import { ErrorComp } from '../../Error';

const defaultColumns: TableColumn[] = [
  { label: 'Id', value: 'entryId', visible: true },
  { label: 'Page title', value: 'pageTitle', visible: true },
  { label: 'Url slug', value: 'urlSlug', visible: false },
  { label: 'Robots', value: 'robots', visible: true },
  { label: 'Updated at', value: 'ts', visible: false },
  { label: 'Updated by', value: 'author', visible: false },
  { label: 'Status', value: 'status', visible: true },
];
export const defaultFilter: FilterContextInputs = {
  optimized: null,
  textFilter: null,
  selfCanonicalized: null,
  valid: null,
  hasProducts: null,
  needsReview: null,
  homeFurnishingBusinessId: null,
  catalogName: null,
  page: 1,
  pageSize: 50,
};

type MetadataEntryTableProps = {
  preFilter?: Partial<FilterInput>;
};

export const MetaDataEntryTable: React.FC<MetadataEntryTableProps> = ({ preFilter }) => {
  const initialFilter = { ...defaultFilter, ...preFilter };
  const defaultSort: SortInput = { direction: 'desc', field: 'search_clicks' };
  const [sort, setSort] = useState<SortInput>(defaultSort);
  const { setMetaDataContext } = useContext(MetaDataContext);
  const { setFilter, filter } = useFilter();
  const location = useLocation();
  const [, , country, language, type] = location.pathname.split('/');
  const {
    state: { roles },
    isAdmin,
  } = useContext(LoginContext);
  const [pending, setPending] = useLoader();
  const [activeIndexListOrSquareView, setActiveIndexListOrSquareView] = useState(0);
  const [chosenColumns, setChosenColumns] = useState<MetaDataTableColumns[]>([]);
  const { exportMetaData } = useMetaDataExport();
  const history = useHistory();
  useEffect(() => {
    setFilter(initialFilter);
  }, []);
  const { loading, data, error, refetch } = useCurrentVersionQuery({
    variables: {
      country,
      type,
      language,
      filter: filter as FilterInput,
      sort,
    },
    onCompleted: (data) => {
      if (!data?.currentVersion) return;
      const isEditorUser = roles.includes(data.currentVersion.country);
      const userCanEdit = (isEditorUser && data.currentVersion.type !== 'PIP') || isAdmin();
      const version = {
        country: data?.currentVersion?.country,
        language: data?.currentVersion?.language,
        type: data?.currentVersion?.type,
        userCanEdit,
        versionId: data.currentVersion._id,
        isDraft: data.currentVersion.isDraft,
      };
      setMetaDataContext(version);
    },
    fetchPolicy: 'no-cache',
  });

  const [locale, setLocale] = useState({
    country: data?.currentVersion?.country,
    language: data?.currentVersion?.language,
    type: data?.currentVersion?.type,
  });
  const [columns, setColumns] = useLocalStorage<TableColumn[]>(`config:metadata-columns-${locale.type}`, defaultColumns);
  const onLocalStorageColumnsChange = (localStorageColumns: MetaDataTableColumns[]) => {
    setChosenColumns(localStorageColumns);
  };
  useEffect(() => {
    onLocalStorageColumnsChange(columns);
  }, [columns]);
  if (data && data.currentVersion && (data?.currentVersion?.language !== locale.language || data?.currentVersion?.country !== locale.country)) {
    setLocale({
      country: data.currentVersion.country,
      language: data.currentVersion.language,
      type: data.currentVersion.type,
    });
  }
  const onSearchChange = (value: string) => {
    setFilter((prev) => ({ ...prev, textFilter: value }));
  };

  const onApply = (filter: FilterContextInputs, sort?: SortInput) => {
    setFilter((prev) => ({ ...prev, ...filter }));
    sort && setSort(sort);
  };
  const clearFilter = () => setFilter(defaultFilter);
  const setPageSize = (option: number) => setFilter((prev) => ({ ...prev, pageSize: option }));
  const deselectFilter = (key: FilterContextInputsKeyWithoutPage) =>
    setFilter((prev) => ({
      ...prev,
      [key as string]: defaultFilter[key as keyof typeof defaultFilter],
    }));
  const clearSort = () => setSort(defaultSort);
  const handleToggleListOrSquareView = (eventObj: BaseSyntheticEvent, index: number) => setActiveIndexListOrSquareView(index);
  const totalPages = data?.currentVersion?.entries.totalPages ?? 0;
  const totalItems = data?.currentVersion?.entries?.totalItems ?? 0;
  const isDraft = data?.currentVersion?.isDraft;
  const isFilterPage = data?.currentVersion?.type === 'filter';
  const draftBodyMessage = `${
    isFilterPage ? 'In edit mode; check "publish" to mark as ready for production.' : ''
  } Press the Publish button in order for the changes to go to production`;
  const isEditorUser = locale.country && roles.includes(locale.country);
  const isLatestVersion = !!data?.currentVersion?.latest;
  const userCanEdit = ((isEditorUser && locale.type !== 'PIP') || isAdmin()) && isLatestVersion;
  const defaultCheckBoxes: CheckboxFields[] = generateColumnToCheckBoxesOptions(columns);
  const defaultColumnsForCheckbox: CheckboxFields[] = generateColumnToCheckBoxesOptions(defaultColumns);
  const uploadClick = () => {
    history.push(`/metadata/upload/${locale.country}/${locale.language}/${locale.type}`);
  };
  const downloadFile = async () =>
    await exportMetaData(
      filter as FilterInput,
      {
        country: locale.country || '',
        language: locale.language || '',
        type: locale.type || '',
      },
      'xlsx',
    );
  const onMetadataColumnsApply = (columnsToDisplay: string[]) =>
    setColumns((prevColumns) => {
      return prevColumns.map((column) => {
        const isColumnVisible = columnsToDisplay.includes(column.value);
        return { ...column, visible: isColumnVisible };
      });
    });
  return (
    <ContainerWrapper>
      {locale && (
        <>
          {isDraft && (
            <Styled.DraftInlineMessage ssrIcon={warningTriangleIcon} variant={'cautionary'} title={'Draft version'} body={draftBodyMessage} />
          )}

          <Control
            pageName={'metadata'}
            isMultiplePolicy={false}
            updateItemsPerPage={setPageSize}
            itemsPerPage={(filter as FilterInput)?.pageSize as number}
            totalItems={totalItems}
            totalPages={totalPages}
            filterType={locale.type}
            defaultSort={defaultSort}
            sort={sort}
            onApplyFilter={onApply}
            setSearchValue={onSearchChange}
            debouncedValue={600}
            searchFieldId={'filter'}
            searchPlaceholder={'Search by keyword'}
            downloadFile={downloadFile}
            uploadFile={uploadClick}
            activeIndexListOrSquareView={activeIndexListOrSquareView}
            onToggleListOrSquareView={handleToggleListOrSquareView}
            defaultCheckBoxes={defaultCheckBoxes}
            defaultColumns={defaultColumnsForCheckbox}
            onColumnsApply={onMetadataColumnsApply}
            clearFilter={clearFilter}
            clearSort={clearSort}
            deselectFilter={deselectFilter}
            defaultFilter={defaultFilter}
            searchFieldTestId={'metadata_search_input'}
            categories={data?.currentVersion?.categories}
          />
        </>
      )}

      {!!error && <ErrorComp errorAction={() => history.go(0)} errorMessage={error.message} errorButtonText={'Retry'} hasPropsError={!!error} />}

      {loading && (
        <Loading text={'Please wait...'}>
          <LoadingBall />
        </Loading>
      )}
      {pending && (
        <Modal visible={pending} escapable={false} handleCloseBtn={() => setPending(false)}>
          <Prompt titleId={'Processing request'} title={'Processing request'} header={<ModalHeader />} footer={null}>
            <Loading text={''}>
              <LoadingBall />
            </Loading>
          </Prompt>
        </Modal>
      )}
      {data?.currentVersion && !loading && (
        <MetaDataEntryTableData
          isEditable={userCanEdit}
          data={data.currentVersion}
          refetchCallback={refetch}
          filter={filter as FilterInput}
          displayListOrSquareView={activeIndexListOrSquareView}
          columns={chosenColumns}
        />
      )}
    </ContainerWrapper>
  );
};
