import React, { FC, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import FileSaver from 'file-saver';
import { request } from 'gaxios';
import ContainerComp from 'components/common/Container';
import { Rules } from 'features/Rules';
import { DeleteRuleModal } from 'features/Rules/DeleteRuleModal';
import { Context as RulesContext } from 'hooks/contexts/RulesContext';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import { FilterContextInputs, FilterContextInputsKeyWithoutPage, useFilter } from 'hooks/contexts/FilterContext';
import { Context as StatusMessageContext, StatusMessageState } from 'hooks/contexts/StatusMessageContext';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { Column as RuleTableColumn } from 'types';
import { useFetchRules } from './rules.service';
import { getPreviousMonthAndYear } from '../../utils/dates';
import { findCorrectPolicy } from '../../utils/policy';
import { Control } from '../../components/Control';
import { SortInput } from '../../__generated__/types';
import { generateColumnToCheckBoxesOptions } from '../../components/Control/helper';

const { month, year } = getPreviousMonthAndYear(new Date());
const DEFAULT_FILTER: FilterContextInputs = {
  targetStatus: null,
  invocations: null,
  page: 1,
  month,
  year,
};

const DEFAULT_SORT: SortInput = {
  field: 'dateModified',
  direction: 'desc',
};

type LocaleDetailProps = RouteComponentProps<{ policy: string; country: string }, {}, StatusMessageState | undefined>;
export const RulesPage: FC<LocaleDetailProps> = ({
  match: {
    params: { policy, country },
  },
  location: { search },
  history: {
    location: { state },
  },
}) => {
  const defaultColumns: RuleTableColumn[] = [
    { label: 'From', value: 'fromRule', visible: true, field: 'matchURL' },
    { label: 'To', value: 'toRule', visible: true, field: 'targetURL' },
    { label: 'Policy name', value: 'policyName', visible: true, field: 'name' },
    { label: 'Usage', value: 'invocations', visible: true, field: 'invocations' },
    { label: 'Start date', value: 'dateStart', visible: false, field: 'dateStart' },
    { label: 'End date', value: 'dateEnd', visible: false, field: 'dateEnd' },
    { label: 'Type', value: 'redirectType', visible: true, field: 'redirectType' },
    { label: 'Target status', value: 'targetStatus', visible: true, field: 'targetStatus' },
    { label: 'Edited by', value: 'userModifiedBy', visible: false, field: 'userModifiedBy' },
  ];

  const COLUMNS_VERSION = 1; // Change this if the defaultColumns are modified
  const [itemsPerPage, updateItemsPerPage] = useState(50);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedRules, setSelectedRules] = useState<string[]>([]);
  const [selectedRulesWithPolicy, setSelectedRulesWithPolicy] = useState<{ policy: string; docRefId: string }[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [columns, setColumns] = useLocalStorage<RuleTableColumn[]>('config:rules-columns', defaultColumns);
  const [currentColumnsVersion, setCurrentColumnsVersion] = useLocalStorage<number>('config:rules-columns-version', 0);
  const { refreshToken } = useContext(LoginContext);
  const { setFilter, filter } = useFilter();
  const isR1Global = policy === 'r1-global';
  const searchParams = new URLSearchParams(search);
  const sortParam = searchParams.get('sort');
  const multiplePolicies = searchParams.get('policy')?.split(',');
  const isMultiPolicies = multiplePolicies && multiplePolicies?.length > 1;
  const breadcrumbPageName = sortParam ? 'defaultUrl' : '';
  const {
    state: { refetchAfter, sort },
    setSelectedRuleValue,
    setUploadRulesModalValues,
    setSortOnFieldAndDirection,
  } = useContext(RulesContext);

  useEffect(() => {
    setFilter(DEFAULT_FILTER);
  }, []);

  useEffect(() => {
    if (sortParam) {
      setSortOnFieldAndDirection({
        field: 'invocations',
        direction: sortParam === 'high' ? 'desc' : 'asc',
      });
    }
  }, [sortParam]);

  const { setStatusMessage } = useContext(StatusMessageContext);

  const { data, loading } = useFetchRules({
    refetchAfter,
    policy,
    pageLimit: itemsPerPage,
    page: filter.page,
    searchValue,
    sort: `${sort.direction ?? DEFAULT_SORT.direction}-${sort.field ?? DEFAULT_SORT.field}`,
    month: filter.month,
    year: filter.year,
    multiplePolicies,
    invocations: filter?.invocations,
    targetStatus: filter?.targetStatus,
  });
  const { rules, totalRules, usageOptions } = data ?? { rules: [], totalRules: 0 };
  useEffect(() => {
    if (COLUMNS_VERSION !== currentColumnsVersion) {
      setColumns(defaultColumns);
      setCurrentColumnsVersion(COLUMNS_VERSION);
    }
  }, []);
  useEffect(() => {
    if (state) {
      const { title, isVisible, variant, bodyText, actions } = state;

      setStatusMessage({
        title,
        isVisible,
        variant,
        bodyText,
        actions,
      });
    }
  }, [state]);

  const visibleColumns = columns.filter((column) => column.visible);
  const defaultCheckBoxes = generateColumnToCheckBoxesOptions(columns);
  const defaultColumnForCheckBoxes = generateColumnToCheckBoxesOptions(defaultColumns);
  const downloadFile = async (policy: string) => {
    const token = await refreshToken();
    try {
      const res = await request<string>({
        method: 'POST',
        responseType: 'blob',
        url: `/api/redirect/rules/${policy}/download`,
        params: { search: searchValue },
        headers: { Authorization: `Bearer ${token}` },
        data: { docRefIds: selectedRules },
      });
      return FileSaver.saveAs(res.data, `${policy}_rules.xlsx`);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteClick = () => {
    setSelectedRuleValue({});
    setIsDeleteModalOpen(true);
  };

  const toggleSelectAll = () => {
    if (selectedRules.length) {
      return clearSelectedRules();
    }
    const ruleIds = rules.map((rule) => rule.docRefId);
    setSelectedRules(ruleIds);
  };

  const toggleSelectAllWithPolicy = () => {
    if (selectedRulesWithPolicy.length) {
      return clearSelectedRulesWithPolicy();
    }
    const ruleIdsAndPolicies = rules.map((rule) => ({
      docRefId: rule.docRefId,
      policy: findCorrectPolicy(policy, rule.matchURL, isR1Global, country),
    }));
    setSelectedRulesWithPolicy(ruleIdsAndPolicies);
  };

  const onRuleToggle = (docRefId: string, policy: string) => {
    if (isMultiPolicies && policy)
      setSelectedRulesWithPolicy((rulesWithPolicy) => {
        if (rulesWithPolicy.some((rule) => rule.docRefId === docRefId && rule.policy === policy)) {
          return rulesWithPolicy.filter((rule) => rule.docRefId !== docRefId || rule.policy !== policy);
        }
        return Array.from(new Set([...rulesWithPolicy, { docRefId, policy }]));
      });
    setSelectedRules((rules) => {
      if (rules.includes(docRefId)) {
        return rules.filter((ruleId) => ruleId !== docRefId);
      }
      return Array.from(new Set([...rules, docRefId]));
    });
  };

  const clearSelectedRules = () => {
    if (selectedRules.length) {
      setSelectedRules([]);
    }
  };

  const clearSelectedRulesWithPolicy = () => {
    if (selectedRulesWithPolicy.length) {
      setSelectedRulesWithPolicy([]);
    }
  };

  const onRulesColumnsApply = (columnsToDisplay: string[]) =>
    setColumns((prevColumns) => {
      return prevColumns.map((column) => {
        const isColumnVisible = columnsToDisplay.includes(column.value);
        return { ...column, visible: isColumnVisible };
      });
    });
  const uploadFile = () => {
    setUploadRulesModalValues({
      isOpen: true,
      country,
      policy,
    });
  };
  const deselectFilter = (filterField: FilterContextInputsKeyWithoutPage) => {
    setFilter((prev) => ({
      ...prev,
      [filterField as string]: DEFAULT_FILTER[filterField as keyof typeof DEFAULT_FILTER],
    }));
  };
  return (
    <ContainerComp page={breadcrumbPageName}>
      <DeleteRuleModal
        isVisible={isDeleteModalOpen}
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}
        onSubmit={() => {
          setIsDeleteModalOpen(false);
          isMultiPolicies ? clearSelectedRulesWithPolicy() : clearSelectedRules();
        }}
        selectedRules={isMultiPolicies ? selectedRulesWithPolicy : selectedRules}
        policy={policy}
        multiplePolicy={multiplePolicies}
      />

      <Control
        pageName={'rules'}
        isMultiplePolicy={!!isMultiPolicies}
        defaultCheckBoxes={defaultCheckBoxes}
        onColumnsApply={onRulesColumnsApply}
        itemsPerPage={itemsPerPage}
        setSearchValue={setSearchValue}
        searchFieldId={'rules_search_input'}
        searchFieldTestId={'rules-search-input'}
        downloadFile={() => downloadFile(policy)}
        uploadFile={uploadFile}
        deleteClick={deleteClick}
        totalItems={totalRules}
        updateItemsPerPage={updateItemsPerPage}
        clearSelectedRowsWithPolicy={clearSelectedRulesWithPolicy}
        clearSelectedRows={clearSelectedRules}
        selectedRows={selectedRules}
        selectedRowsWithPolicy={selectedRulesWithPolicy}
        sort={sort}
        defaultSort={sort}
        defaultFilter={DEFAULT_FILTER}
        onApplyFilter={(filter: FilterContextInputs) => setFilter(filter)}
        clearFilter={() => setFilter(DEFAULT_FILTER)}
        clearSort={() => setSortOnFieldAndDirection(DEFAULT_SORT)}
        deselectFilter={deselectFilter}
        defaultColumns={defaultColumnForCheckBoxes}
        usageOptions={usageOptions}
      />

      <Rules
        selectedRules={isMultiPolicies ? selectedRulesWithPolicy : selectedRules}
        isLoading={loading}
        rules={rules}
        onRuleToggle={onRuleToggle}
        toggleSelectAll={isMultiPolicies ? toggleSelectAllWithPolicy : toggleSelectAll}
        clearSelectedRules={isMultiPolicies ? clearSelectedRulesWithPolicy : clearSelectedRules}
        columns={visibleColumns}
        policy={policy}
        hideActionsIcon={true}
        isMultiPolicy={isMultiPolicies}
      />
    </ContainerComp>
  );
};
