import React, { useContext, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Hyperlink from '@ingka/hyperlink';
import Text from '@ingka/text';
import { History } from 'history';
import languages from 'iso-639-1';
import Container from 'components/common/Container';
import { CountryFlag } from 'components/common/CountryFlag';
import { FileUploadForm } from 'components/seo/FileUploadForm';
import { getCountryName } from 'opt-util/helpers';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import { MetadataType } from 'types';
import * as Styled from 'components/common/styles';
import { Context as ErrorContext } from 'hooks/contexts/ErrorMessageContext';

const postFile = async (file: File, countryPath: string, refreshToken: () => Promise<string>, history?: History) => {
  const data = new FormData();
  data.append('file', file);
  const token: string = await refreshToken();

  const result = await fetch(`/api/optimera/metadata/upload${countryPath}`, {
    method: 'POST',
    body: data,
    headers: { Authorization: `Bearer ${token}` },
  });
  if (result.status === 200) {
    const resultData = await result.json();
    history?.push(`/metadata/uploadResult${countryPath}`, { uploadId: resultData._id });
  } else {
    return result;
  }
};

const rules = [
  ['id', 'REQUIRED'],
  ['pageTitle', 'should not contain the word IKEA'],
  ['description', 'should not contain URLs'],
  ['urlSlug', 'should contain words with only letters and numbers; it can contain hyphens between words'],
].map(([fieldName, rule]) => (
  <li key={fieldName}>
    <b>{fieldName}</b>: {rule}
  </li>
));

const CustomMessage: React.FC<UploadCompProps> = ({ country, language, type }) => (
  <>
    <LocaleAndTypeHeader type={type} country={country ?? ''} language={language ?? ''} />
    <Styled.Spacer>
      <Text tagName="p">
        Please choose a CSV or XLSX file with the rows that you want to update. The file doesn&apos;t need to contain all the entries for the given
        version, only those that need to be updated. The only required column is ID, any other missing column will be ignored: it is possible, for
        example, to upload a file with only ID and pageTitle to update only the title of the given IDs.
        {type === 'filter' && (
          <>
            <br />
            <strong>NOTE: </strong>Uploading rows with non-existing ids will not create new filtered pages. Filtered pages has to be created through
            the {<Hyperlink url={'/admin/filtered-pages'} text={'admin tool'} />}.
          </>
        )}
      </Text>
      <Text tagName="p">The following rules will apply:</Text>

      <Styled.SpacedList>{rules}</Styled.SpacedList>

      <Text tagName="p">
        The rows that don&apos;t meet the above requirements will not be updated and an error will be displayed. If you would like to see an example
        file, <a href={`/country_lang_${type}.xlsx`}>click the link here</a>. You will be able to review the changes in the next step and then approve
        them before the publish is made.
      </Text>
      <Text tagName="p">
        <b>You&apos;ll need to click Publish on the next page to apply the changes.</b>
      </Text>
    </Styled.Spacer>
  </>
);

interface UploadCompProps {
  country: string;
  language: string;
  type: MetadataType;
}

export const UploadComp: React.FC<RouteComponentProps<UploadCompProps>> = (props) => {
  const { country, language, type } = props.match.params;
  const countryPath = `/${country}/${language}/${type}`;
  const {
    state: { roles },
    refreshToken,
    isAdmin,
  } = useContext(LoginContext);
  const isEditorUser = roles.includes(country);
  const isReadOnlyUser = !isAdmin() && !isEditorUser;
  const { goBack } = useHistory();
  const { setErrorMessage } = useContext(ErrorContext);

  useEffect(() => {
    if (isReadOnlyUser || (isEditorUser && type === 'PIP')) {
      setErrorMessage({
        message: 'Let’s get you back on track…',
        title: '401 Not Authorized',
        action: () => goBack(),
      });
    }
  }, [isReadOnlyUser, isEditorUser, type]);

  return (
    <Container page="uploadCountry">
      <FileUploadForm
        customMessage={<CustomMessage {...props.match.params} />}
        postFile={(file, history) => postFile(file, countryPath, refreshToken, history)}
      />
    </Container>
  );
};

const LocaleAndTypeHeader: React.FC<{ country: string; language: string; type: string }> = (props) => (
  <Styled.Header>
    <CountryFlag countryCode={props.country} />
    Upload {props.type.toUpperCase()}: {getCountryName(props.country)} - {languages.getName(props.language)}
  </Styled.Header>
);

export default UploadComp;
