import React from 'react';
import { useLocation } from 'react-router-dom';
import { MetaDataEntryTable } from 'components/seo/metadataView/MetadataEntryTable';
import { FilterInput } from '../../__generated__/types';

type DataViewPageState = Partial<FilterInput>;

export const DataViewPage: React.FC = () => {
  const location = useLocation();
  const preFilter = (location?.state as DataViewPageState) || {};
  return <MetaDataEntryTable preFilter={preFilter} />;
};
