import React, { useState } from 'react';
import plus from '@ingka/ssr-icon/paths/plus';
import { YellowButton as AddRuleButton } from 'components/styles';
import { AddEditRuleModal } from 'features/Rules/AddEditRuleModal';
import { useMarkets } from 'hooks/useMarkets';
import { useCountry } from 'hooks/useCountry';

export const AddRedirectRuleModalButton: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [country] = useCountry();
  const { editableMarkets } = useMarkets();
  const isEditingAllowed = editableMarkets.some((locale) => locale.country === country?.countryCode);

  return (
    <>
      <AddRuleButton
        text={'Add rule'}
        disabled={!isEditingAllowed}
        ssrIcon={plus}
        type={'secondary'}
        size={'small'}
        iconPosition={'trailing'}
        onClick={() => setModalOpen(true)}
        data-testid={'addRuleModalButton'}
      />

      <AddEditRuleModal
        isVisible={modalOpen}
        onCancel={() => setModalOpen(false)}
        onSubmit={() => setModalOpen(false)}
        header={'Add Rule'}
        toolTipText={'Use this form to add a new redirect rule'}
      />
    </>
  );
};
